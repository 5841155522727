<template>
  <div>
    <Hero
      title="Pricing"
      :palette="['#4b79cf', '#5176cf']"
      :angle="120"
      :dark="true"
      :gradientAnimation="true"
      :transparency="true"
      :fillHeight="false"
      animationName="fade-in"
    />

    <v-container>
      <div class="my-9">
        <div class="text-h3 mb-6" data-aos="fade-in">Free for 1 month</div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          Our pricing structure is simple and has no hidden costs.
        </div>

        <v-list-item two-line data-aos="fade-in">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Enterprise plan</v-list-item-title>
            <v-list-item-subtitle class="text-wrap"
              >Create unlimited events for your colleagues and anyone in the
              world from as low as 99p per user per month for everyone at your
              organisation.</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line data-aos="fade-in">
          <v-list-item-icon>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Custom plans</v-list-item-title>
            <v-list-item-subtitle class="text-wrap"
              >Customised plans are available for small business, education and
              non-profit. Please contact us.</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <Actions
          class="my-6"
          :firstActionText="firstActionText"
          :firstActionUrl="firstActionUrl"
          :firstActionTarget="firstActionTarget"
          :secondActionText="secondActionText"
          :secondActionUrl="secondActionUrl"
          :secondActionTarget="secondActionTarget"
          :dark="false"
        />

        <!--
        <v-form
          v-model="validForm"
          lazy-validation
          ref="form"
          data-aos="fade-in"
          data-aos-delay="200"
          
          class="py-3"
        >
          <v-text-field
            outlined
            label="Name"
            v-model="name"
            :rules="[(v) => !!v || 'Required']"
            required
            ref="name"
          ></v-text-field>

          <v-text-field
            outlined
            label="Email"
            v-model="email"
            type="email"
            :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Must be valid']"
            required
          ></v-text-field>

          <v-checkbox
            class="my-0"
            v-model="agreement"
            required
            :rules="[(v) => !!v || 'You must agree to continue!']"
          >
            <template v-slot:label>
              <div>
                I agree to the
                <a
                  class="text-decoration-none"
                  @click.stop
                  target="_blank"
                  href="https://abardo.com/docs/privacy-policy"
                  >privacy policy</a
                >
                and
                <a
                  class="text-decoration-none"
                  @click.stop
                  target="_blank"
                  href="https://abardo.com/docs/terms-of-use"
                  >terms and conditions</a
                >.
              </div>
            </template>
          </v-checkbox>

          <v-btn
            class="mt-3"
            :block="$vuetify.breakpoint.xsOnly"
            dark
            rounded
            elevation="0"
            x-large
            :loading="isSending"
            @click="validate"
            ><v-icon>mdi-send</v-icon>
            <div class="ml-1">Send</div></v-btn
          >
        </v-form>
        -->

        <!--<v-snackbar v-model="snack.show" color="black">
          {{ snack.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snack.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>-->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

import Hero from "@/components/shared/Hero";
import Actions from "@/components/shared/Actions";

export default {
  components: { Hero, Actions },
  metaInfo: () => ({
    title: "Abardo - Pricing",
  }),
  data() {
    return {
      /*isSending: false,
      validForm: false,
      name: null,
      email: null,
      message: "Please tell me more about pricing.",
      agreement: false,
      snack: {
        show: false,
        text: null,
      },*/
      firstActionText: "Try free now",
      firstActionUrl: "https://app.abardo.com/sign-in",
      firstActionTarget: "_blank",
      secondActionText: "Contact us",
      secondActionUrl: "/contact",
      secondActionTarget: "_self",
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.name.focus();
    }, 500);
  },
  methods: {
    async send() {
      if (this.isSending) return;
      this.isSending = true;

      try {
        await axios({
          method: "put",
          url: "https://abardo-api.azurewebsites.net/email/message/external",
          data: {
            name: this.name,
            email: this.email,
            message: this.message,
          },
        });
      } catch (error) {
        this.snackbar = {
          show: true,
          message: error.response.data,
        };
      }

      this.snack = {
        show: true,
        text: "Thanks! We will get back to you as soon as possible.",
      };
      this.isSending = false;
      this.reset();
    },
    validate() {
      this.validForm = this.$refs.form.validate();
      if (this.validForm) this.send();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
